<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-toolbar
        dark
        :color="$vuetify.theme.themes.light.primary"
        dense
        elevation="0"
    >
      <v-toolbar-title><span>{{title}}</span></v-toolbar-title>
      <v-spacer/>
      <v-btn icon dark @click="closeHandler()">
        <v-icon>{{icon}}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-progress-linear
          :active="progress"
          color="primary"
          height="10"
          indeterminate
      />
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    progress: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeHandler() {
      this.$emit('closeDialogHandler')
    }
  }
}
</script>

<style scoped>

</style>